import { BasicsClass } from '@/common/BasicsClass';
export class CouponsManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getCoupons', {
            url: '/admin/coupons/list',
            name: 'list',
            label: '列表'
        }],
        ['addCoupons', {
            url: '/admin/coupons/add',
            name: 'add',
            label: '添加'
        }],
        ['updateCoupons', {
            url: '/admin/coupons/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['deleteCouponItem', {
            url: '/admin/coupons/delete',
            name: 'delete',
            label: '删除'
        }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
            // hidden: true
        }],
        ['getCouponTimeDurations', {
            url: '/admin/coupons/timeList',
            name: 'coupon-time',
            label: '优惠券可用时段',
            // hidden: true
        }],
        ['poolTableCategory', {
            url: '/admin/poolTableCategory/list',
            name: 'pool-category',
            label: '台桌/包厢类型',
            // hidden: true
        }],
        ['getPreviewFileTable', {
            url: '/admin/couponsShopImport/preview',
            name: 'import-shop-preivew',
            label: '导入店铺预览列表',
        }],
    ])


    //新增优惠券
    public addCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //修改优惠券
    public updateCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //优惠券列表
    public getCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除 优惠券
    public deleteCouponItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteCouponItem').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 搜索店铺列表
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    //卡券时间段列表
    public getCouponTimeDurations(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getCouponTimeDurations').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //球桌分类列表
    public poolTableCategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('poolTableCategory').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //导入店铺预览列表
    public getPreviewFileTable(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getPreviewFileTable').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}